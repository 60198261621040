import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { CloseIcon, Container, Description, Input, Placeholder, RightIcon, StyledInput } from './Components';
import InputError from './InputError';
import { ArrowDown, Plus } from '#Images';
import { ArrowContainer } from '../Dropdown';
import styled from 'styled-components';
const StyledArrowContainer = styled(ArrowContainer) `
  flex-direction: column;
  gap: 8px;
  transform: translateY(-50%);

  img:first-child {
    transform: rotate(180deg) translateY(-50%);
  }
`;
const FormInput = ({ onChange, onClear, name, value, type = 'text', description, errorText, ref, ariaLabel, bottomMargin = 0, iconUrl, rightIconUrl, disabled, onFocus, onBlur, readonly, comboArrows, }) => {
    const [active, setActive] = useState(false);
    const inputRef = useRef(null);
    const slide = () => {
        if (!value) {
            setActive(!active);
        }
    };
    useEffect(() => {
        if (value)
            setActive(true);
    }, [value]);
    const onContainerClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const onInputFocus = () => {
        slide();
        onFocus && onFocus();
    };
    const onInputBlur = () => {
        slide();
        onBlur && onBlur();
    };
    return (_jsxs(StyledInput, { "$bottomMargin": bottomMargin, children: [_jsxs(Container, { disabled: disabled, active: active, error: !!errorText, onClick: onContainerClick, children: [iconUrl && _jsx("img", { style: { width: 20, height: 20 }, className: "input-icon", src: iconUrl }), _jsx(Placeholder, { style: { marginLeft: iconUrl && !active ? 27 : 0 }, active: active, children: name }), _jsx(Input, { readOnly: readonly, autoComplete: 'off', style: { marginLeft: iconUrl ? 27 : 0 }, isFilled: !!value, "aria-label": ariaLabel, id: "form-input-new", ref: ref || inputRef, onBlur: onInputBlur, onFocus: onInputFocus, value: value, onChange: e => onChange(e.target.value), step: type === 'number' ? 0.1 : undefined, type: type, disabled: disabled }), onClear && value && _jsx(CloseIcon, { onClick: onClear, src: Plus }), rightIconUrl && !onClear && _jsx(RightIcon, { src: rightIconUrl, alt: "icon" }), comboArrows && (_jsxs(StyledArrowContainer, { "$opened": false, children: [_jsx("img", { src: ArrowDown, alt: "arrow down", width: 10, height: 6 }), _jsx("img", { src: ArrowDown, alt: "arrow down", width: 10, height: 6 })] }))] }), !!description && !errorText && _jsx(Description, { children: description }), !!errorText && _jsx(InputError, { children: errorText })] }));
};
export default FormInput;
