import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { CheckMarkFox } from '#Images';
const ItemContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--main-gray);
  padding: 12px 0px;
`;
const PlusBtn = styled.div `
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    width: 2px;
    height: 10px;
    background-color: var(--main-color);
  }
  ::before {
    width: 10px;
    height: 2px;
  }
`;
const Mark = styled.div `
  background-image: url(${CheckMarkFox});
  width: 24px;
  height: 24px;
`;
const SearchFurnitureItem = ({ title, isSelected, onClick }) => {
    return (_jsxs(ItemContainer, { onClick: onClick, children: [_jsx("span", { children: title }), isSelected ? _jsx(Mark, {}) : _jsx(PlusBtn, {})] }));
};
export default SearchFurnitureItem;
