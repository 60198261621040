import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
class ErrorBoundary extends Component {
    state = {
        hasError: false,
        message: '',
    };
    componentDidCatch(error, info) {
        console.log('Error: ', error);
        console.log('Info: ', info);
        this.setState({ hasError: true, message: `${error}` });
    }
    render() {
        if (this.state.hasError) {
            return (_jsx("div", { className: 'main-block', children: _jsxs("div", { style: {
                        height: '100vh ',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }, children: [_jsx("span", { style: { fontWeight: 'bold', fontSize: '14px', marginTop: '30px' }, children: "Oops, something went wrong" }), _jsx("p", { style: { marginTop: 40 }, children: this.state.message })] }) }));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
