import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import SwipeContainer from '../SwipeContainer';
import { HeaderButton, HeaderButtons } from '#Components/CommonStyled';
import Loader from '#Components/shared/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '#Services';
import { useToast } from '../../../providers/ToastProvider';
const api = new ApiService();
const RemoveUnit = ({ onClose, open, onRemove }) => {
    const { addToast } = useToast();
    const [updating, setUpdating] = useState(false);
    const { unitId, storageId } = useParams();
    const navigate = useNavigate();
    const [beforeRemoveTimer, setBeforeRemoveTimer] = useState(5000);
    useEffect(() => {
        const timer = setInterval(() => {
            setBeforeRemoveTimer(t => t - 1000);
        }, 1000);
        if (beforeRemoveTimer === 0) {
            clearInterval(timer);
        }
        return () => {
            clearInterval(timer);
        };
    }, [beforeRemoveTimer]);
    const onRemoveUnit = async () => {
        try {
            setUpdating(true);
            await api.removeUnit(Number(unitId));
            addToast &&
                addToast({
                    message: 'Unit successfully removed',
                    type: 'success',
                });
            navigate(`/storage/${storageId}`);
        }
        catch (error) {
            addToast &&
                addToast({
                    message: 'Something went wrong',
                    type: 'error',
                });
        }
        finally {
            setUpdating(false);
        }
    };
    return (_jsx(_Fragment, { children: _jsx(SwipeContainer, { opened: open, onClose: onClose, children: _jsxs("div", { id: "transfer_unit_list", style: { display: 'flex', flexDirection: 'column', minHeight: '80vh' }, children: [_jsxs(HeaderButtons, { children: [_jsx(HeaderButton, { onClick: onClose, children: "Cancel" }), _jsx(HeaderButton, { disabled: beforeRemoveTimer > 0, 
                                // $active={!!selectedUnitId && !transferring}
                                onClick: onRemoveUnit, children: "Remove" })] }), !!beforeRemoveTimer && _jsxs("p", { children: ["You can remove unit after ", beforeRemoveTimer / 1000, " seconds"] }), updating && _jsx(Loader, {})] }) }) }));
};
export default RemoveUnit;
