import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowDown } from '#Images';
import dayjs from 'dayjs';
import { calculateTotalItems } from '#Utils/helpers';
import { StatusCodeToColor, StatusCodeToText } from '#Services/storage';
const ViewSpaceBtn = styled.button `
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  img {
    width: 9px;
    height: 10px;
    margin-left: 10px;
    transform: rotate(-90deg);
  }
`;
const StatusMark = styled.span `
  font-size: 14px;
  width: fit-content;
  background-color: ${({ color }) => color || '#daf5de'};
  border-radius: 4px;
  padding: 2px 6px;
`;
const Space = styled.h2 `
  ${DesignFonts.bodyDemiBold};
`;
const Unit = styled.span `
  ${DesignFonts.bodyRegular};
`;
const ReturnInfoLink = ({ returnItem }) => {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const onViewReturnClick = () => {
        navigate(`/clients/${clientId}/return/${Number(returnItem.hubspot_order_id)}`);
    };
    return (_jsxs("div", { onClick: onViewReturnClick, style: { display: 'flex', justifyContent: 'space-between', marginBottom: 12, cursor: 'pointer' }, children: [_jsxs("div", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx(Space, { children: dayjs(returnItem.order_date).format('DD MMMM YYYY') }), _jsxs(Unit, { children: ["Total items: ", calculateTotalItems(returnItem.items)] }), _jsx(StatusMark, { color: StatusCodeToColor[returnItem.stage], children: StatusCodeToText[returnItem.stage] })] }), _jsx(ViewSpaceBtn, { children: _jsx("img", { src: ArrowDown }) })] }));
};
export default ReturnInfoLink;
