import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
const BlockContainer = styled.div `
  margin-bottom: 24px;
  width: 100%;
`;
const BlockHeader = styled.div `
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TitleWithIcon = styled.div `
  ${DesignFonts.headlineH5}
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }
`;
const RightBtn = styled.button `
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  ${DesignFonts.bodyDemiBold}
  flex-shrink: 0;

  img {
    margin-right: 8px;
    width: 16px;
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
const BLockWithIcon = ({ iconUrl, title, children, rightBtnProps }) => {
    return (_jsxs(BlockContainer, { children: [_jsxs(BlockHeader, { children: [_jsxs(TitleWithIcon, { children: [iconUrl && _jsx("img", { src: iconUrl, alt: "icon" }), _jsx("span", { children: title })] }), rightBtnProps && (_jsxs(RightBtn, { disabled: rightBtnProps.disabled, onClick: rightBtnProps.onClick, children: [_jsx("img", { src: rightBtnProps.iconUrl, alt: "icon" }), rightBtnProps.title] }))] }), children] }));
};
export default BLockWithIcon;
