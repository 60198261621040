import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BlockWithIcon, Button, FormInput } from '#Components/shared';
import { useState } from 'react';
import DatePickerBlock from './DatePickerBlock';
import { ButtonKinds } from '#Components/shared/Button/Button';
import Header from '#Components/Header/Header';
import dayjs from 'dayjs';
import styled from 'styled-components';
const BottomBtnWrapper = styled.div `
  /* position: absolute;  */
  /* bottom: 20px;  */
  /* width: calc(100% - 32px); */
  /* margin-top: calc(100% - 52px); */
  width: 100%;
  margin-top: 36px;
`;
const SavingPage = ({ title, subtitle, btnProps, onClose, onBackClick }) => {
    const [orderId, setOrderId] = useState('');
    const [sealNumber, setSealNumber] = useState('');
    const [date, setDate] = useState(new Date());
    const [errors, setErrors] = useState({
        orderId: false,
    });
    const onSetOrderId = (value) => {
        setOrderId(value);
        setErrors({
            ...errors,
            orderId: false,
        });
    };
    const onSetSealNumber = (value) => {
        setSealNumber(value);
    };
    const onBtnClick = () => {
        if (!orderId) {
            return setErrors({
                orderId: !orderId,
            });
        }
        const data = {
            order_id: Number(orderId),
            new_unit_seal_number: sealNumber,
            date: dayjs(date).format('YYYY-MM-DD'),
        };
        btnProps.onClick(data);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, { onBackBtnClick: onBackClick, title: title, subtitle: subtitle, onClose: onClose }), _jsxs(BlockWithIcon, { title: "Enter the order details", children: [_jsx(FormInput, { errorText: errors.orderId ? 'Field required' : '', name: 'Order ID', onChange: onSetOrderId, value: orderId, bottomMargin: 12 }), _jsx(FormInput, { name: 'Seal Number', description: "Change the seal number if needed", onChange: onSetSealNumber, value: sealNumber, bottomMargin: 12 })] }), _jsx(DatePickerBlock, { title: "Choose date", date: date, onSelectDate: setDate }), _jsx(BottomBtnWrapper, { children: _jsx(Button, { loading: btnProps.isLoading, full: true, type: "submit", onClick: onBtnClick, kind: ButtonKinds.primary, children: btnProps.title }) })] }));
};
export default SavingPage;
