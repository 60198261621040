import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getNumberFromString } from '#Utils/helpers';
import CartItem from './CartItem';
import { Document } from '#Images';
import { useToast } from '../../../providers/ToastProvider';
export const RightBlockContainer = styled.div `
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;

  width: 100%;
  display: flex;
  flex-direction: column;
`;
const TotalItems = styled.div `
  margin: 8px 0;
  text-align: center;
  color: var(--main-gray-dark);
  font-weight: 600;
  font-size: 14px;
`;
const CopyButton = styled.button `
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 0;

  img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;
const ItemsCart = ({ furnitureItems, setItems, isEdit }) => {
    const { addToast } = useToast();
    const [isCopyBtnDisabled, setIsCopyBtnDisabled] = useState(false);
    const totalItems = furnitureItems.reduce((acc, item) => acc + item.amount, 0);
    const copyToClipboard = () => {
        setIsCopyBtnDisabled(true);
        const text = furnitureItems.map(item => `${item.title} - ${item.amount}`).join('\n');
        navigator.clipboard.writeText(text);
        addToast &&
            addToast({
                message: 'Items copied to clipboard',
                type: 'success',
            });
        setTimeout(() => {
            setIsCopyBtnDisabled(false);
        }, 2500);
    };
    return (_jsx(_Fragment, { children: _jsxs(RightBlockContainer, { children: [furnitureItems.map((item, index) => (_jsx(CartItem, { edit: isEdit, onRemove: () => setItems(item.title, null), title: item.title, amount: item.amount, onChange: v => setItems(item.title, getNumberFromString(v)) }, index))), totalItems && _jsxs(TotalItems, { children: ["Total items: ", totalItems] }), _jsxs(CopyButton, { disabled: isCopyBtnDisabled, onClick: copyToClipboard, children: [_jsx("img", { src: Document }), "Copy items to clipboard"] })] }) }));
};
export default observer(ItemsCart);
