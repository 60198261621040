import { jsx as _jsx } from "react/jsx-runtime";
import { Spinner } from './Button/Components';
const Loader = () => {
    return (_jsx("div", { style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: 'transparent',
            position: 'fixed',
            zIndex: 999999,
        }, children: _jsx(Spinner, { width: 42, height: 42, viewBox: "0 0 50 50", children: _jsx("circle", { className: "path", cx: "25", cy: "25", r: "20", fill: "none", strokeWidth: "5" }) }) }));
};
export default Loader;
