import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Container, SlotBlock, SlotBlockContent, SlotItem } from './Components';
import dayjs from 'dayjs';
import { useErrorToastEffect, useStores } from '#Hooks';
import { sixHoursTimeSlots as sixHrsSlots, twoHoursTimeSlots as twoHrsSlots, sixHoursTimeSlotsFull as sixHrsSlotsFull, twoHoursTimeSlotsFull as twoHrsSlotsFull, } from '#Constants/dateConst';
import DatePicker from './DatePicker/DatePicker';
import { Button } from '#Components/shared';
import { BottomButtonsWrapper } from '../components';
import { getSlot } from '#Utils/helpers';
export const checkIfNotWeekend = (date) => {
    return date.getDay() !== 0 && date.getDay() !== 6;
};
const DateModule = () => {
    const { storageReturnData: { totalPricePerSlot, setTotalCount, setCalculationId, calculatePrice, setCurrentStepIndex, currentStepIndex, date, timeSlot, setTimeSlot, setDate, clientInfo, }, appData, } = useStores();
    const currentStore = clientInfo?.storage_title;
    const isSTOREBOX = currentStore === 'STOREBOX';
    const sixHoursTimeSlots = isSTOREBOX ? sixHrsSlotsFull : sixHrsSlots;
    const twoHoursTimeSlots = isSTOREBOX ? twoHrsSlotsFull : twoHrsSlots;
    const onNextClick = () => {
        setCurrentStepIndex(currentStepIndex + 1);
    };
    const onSelectDate = (d) => {
        if (date === d)
            return;
        setDate(d);
    };
    const onSelectTimeSlot = (v) => {
        if (timeSlot[0] === v[0])
            return;
        setTimeSlot(v);
    };
    const getMinDate = () => {
        if (isSTOREBOX) {
            return dayjs().add(1, 'day').toDate();
        }
        return dayjs().add(3, 'day').toDate();
    };
    useEffect(() => {
        if (date) {
            calculatePrice();
        }
    }, [date]);
    useErrorToastEffect(appData.error, 'Something went wrong');
    return (_jsxs(_Fragment, { children: [_jsxs(Container, { children: [_jsx(DatePicker, { minDate: getMinDate(), value: date, onChange: date => onSelectDate(date) }), _jsxs(SlotBlock, { children: [_jsx("h5", { style: { fontSize: 18, marginBottom: 6 }, children: "6 hours slots" }), _jsx(SlotBlockContent, { children: sixHoursTimeSlots.map((item, key) => {
                                    const slotPrice = totalPricePerSlot.find(price => {
                                        const slot = getSlot(price.datetime, price.slot_duration_hours);
                                        return item[0] === slot[0] && item[1] === slot[1];
                                    });
                                    const price = slotPrice?.response.moving.total_price_with_discount || 0;
                                    return (_jsxs(SlotItem, { onClick: () => {
                                            setCalculationId(slotPrice?.id || null);
                                            setTotalCount(slotPrice?.response.moving);
                                            onSelectTimeSlot(item);
                                        }, isChecked: item[0] === timeSlot[0] && item[1] === timeSlot[1], children: [`${item[0]}:00 - ${item[1]}:00`, appData.process ? _jsx("p", { children: "calculating..." }) : _jsx("p", { style: { fontWeight: 600 }, children: `£${price}` })] }, key));
                                }) }), _jsx("h5", { style: { fontSize: 18, marginBottom: 6, marginTop: 24 }, children: "2 hours slots" }), _jsx(SlotBlockContent, { children: twoHoursTimeSlots.map((item, key) => {
                                    const slotPrice = totalPricePerSlot.find(price => {
                                        const slot = getSlot(price.datetime, price.slot_duration_hours);
                                        return item[0] === slot[0] && item[1] === slot[1];
                                    });
                                    const price = slotPrice?.response.moving.total_price_with_discount || 0;
                                    return (_jsxs(SlotItem, { onClick: () => {
                                            setCalculationId(slotPrice?.id || null);
                                            setTotalCount(slotPrice?.response.moving);
                                            onSelectTimeSlot(item);
                                        }, isChecked: item[0] === timeSlot[0] && item[1] === timeSlot[1], children: [`${item[0]}:00 - ${item[1]}:00`, appData.process ? _jsx("p", { children: "calculating..." }) : _jsx("p", { style: { fontWeight: 600 }, children: `£${price}` })] }, key));
                                }) })] })] }), _jsx(BottomButtonsWrapper, { children: _jsx(Button, { disabled: appData.process || !!appData.error, onClick: onNextClick, full: true, 
                    // $mobileSmall
                    kind: 'primary', loading: false, type: "button", children: "Continue" }) })] }));
};
export default observer(DateModule);
