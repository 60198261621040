import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CircleChart } from '#Components/shared';
import { getChartColorByPercent } from '#Components/shared/CircleChart/CircleChart';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
const ContentBlock = styled.div `
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const BottomBlock = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Indicator = styled.div `
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--main-gray);
`;
const IndicatorBlock = styled.div `
  ${DesignFonts.smallPrint}
  align-items: center;
  display: flex;
  & > div {
    margin-right: 4px;
  }
`;
const ChartBlock = ({ percentage }) => {
    return (_jsx(_Fragment, { children: _jsxs(ContentBlock, { children: [_jsx(CircleChart, { percentage: percentage }), _jsxs(BottomBlock, { children: [_jsxs(IndicatorBlock, { children: [_jsx(Indicator, { style: { backgroundColor: getChartColorByPercent(percentage) } }), _jsx("span", { children: "booked" })] }), _jsxs(IndicatorBlock, { children: [_jsx(Indicator, {}), _jsx("span", { children: "free" })] })] })] }) }));
};
export default ChartBlock;
