import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import Toggle from '../Toggle/Toggle';
const Row = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
`;
const ToggleRow = ({ title, iconUrl, onChange, isChecked, label }) => {
    const onRowClick = () => {
        onChange(!isChecked);
    };
    return (_jsxs(Row, { children: [_jsx(Toggle, { checked: isChecked, label: label, onToggle: onRowClick }), _jsx("span", { onClick: onRowClick, children: title })] }));
};
export default ToggleRow;
