import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '#Components/shared';
import { useEffect, useMemo, useState } from 'react';
import Header from '#Components/Header/Header';
import { ArchiveIcon, ClockIcon, EditIcon, ManIcon2, ReturnIcon } from '#Images';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import { ButtonKinds } from '#Components/shared/Button/Button';
import MediaBlock from './components/MediaBlock';
import ItemsBlock from './components/ItemsBlock';
import useStores from '#Hooks/useStores';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import TransferSpace from './components/TransferSpace';
import SavingPage from './components/SavingPage';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiService } from '#Services';
import { useErrorToastEffect, useSuccessToastEffect } from '#Hooks';
import Loader from '#Components/shared/Loader';
import TimeLineModal from '#Components/TimeLine/TimeLineModal';
import CommentBlock from './components/CommentBlock';
import { getSpaceName } from '#Utils/helpers';
const api = new ApiService();
const AddSpace = ({ onClose, created }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
    const [isDeletePageOpen, setIsDeletePageOpen] = useState(false);
    const [isHistoryPageOpen, setIsHistoryPageOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const { spaceId = '', unitId = '', storageId = '' } = useParams();
    const { spaceData, appData } = useStores();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data, error, isLoading } = useQuery({
        enabled: !!created,
        // refetchOnMount: 'always',
        // refetchOnReconnect: 'always',
        queryKey: ['space', { storageId, unitId, spaceId }],
        queryFn: ({ queryKey }) => {
            const { storageId, unitId, spaceId } = queryKey[1];
            return api.getSpace(storageId, unitId, spaceId);
        },
    });
    useErrorToastEffect(error, 'Error with space loading');
    useErrorToastEffect(appData.error, 'Something went wrong');
    useSuccessToastEffect(successMessage);
    const onDeleteSpace = async (info) => {
        const isDeleted = await spaceData.onDeleteSpace(storageId, unitId, spaceId, info);
        setSuccessMessage('');
        if (isDeleted) {
            setSuccessMessage(`Space ${data?.id} successfully archived`);
            spaceData.clean();
        }
    };
    const onAddSpace = async (orderData) => {
        setSuccessMessage('');
        const isAdded = await spaceData.onAddSpace(unitId, storageId, orderData, created ? spaceId : '');
        if (isAdded) {
            setSuccessMessage('Space successfully added');
            spaceData.clean();
        }
    };
    const onTransferSpace = async (newUnitId) => {
        setSuccessMessage('');
        const isTransferred = await spaceData.onTransferSpace(unitId, storageId, spaceId, newUnitId);
        if (isTransferred) {
            setSuccessMessage('Space successfully transferred');
        }
    };
    useEffect(() => {
        if (successMessage) {
            navigate(`/storage/${storageId}/${unitId}`);
        }
    }, [successMessage]);
    useEffect(() => {
        if (data) {
            spaceData.setSpaceData(data);
        }
    }, [data]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const onViewClientClick = () => {
        navigate(`/clients/${data?.user_id}`);
    };
    const menuItems = useMemo(() => [
        {
            title: 'Edit space',
            iconUrl: EditIcon,
            onClick: () => spaceData.setIsEdit(true),
        },
        {
            title: 'View client',
            iconUrl: ManIcon2,
            onClick: () => onViewClientClick(),
        },
        {
            title: 'Transfer space',
            iconUrl: ReturnIcon,
            onClick: () => setIsTransferModalOpen(true),
        },
        {
            title: 'View timeline',
            iconUrl: ClockIcon,
            onClick: () => setIsHistoryPageOpen(true),
        },
        {
            title: 'Archive space',
            iconUrl: ArchiveIcon,
            onClick: () => setIsDeletePageOpen(true),
        },
    ], [data]);
    useEffect(() => {
        spaceData.fetchStorageItems();
        if (!created) {
            spaceData.setIsEdit(true);
            spaceData.clean();
        }
        return () => {
            spaceData.setIsEdit(false);
            spaceData.clean();
        };
    }, []);
    const isEditable = () => !created || spaceData.isEdit;
    const getSpaceTitle = () => {
        if (created && !spaceData.isEdit) {
            return data ? getSpaceName(data) : 'Space';
        }
        if (!created && spaceData.isEdit) {
            return 'Add space';
        }
        return 'Edit space';
    };
    useEffect(() => {
        if (error) {
            navigate(`/storage/${storageId}/${unitId}`);
        }
        return () => {
            if (error) {
                queryClient.clear();
            }
        };
    }, [error]);
    if (error) {
        return null;
    }
    if (isLoading) {
        return _jsx(Loader, {});
    }
    if (isDeletePageOpen) {
        return (_jsx(SavingPage, { onBackClick: () => setIsDeletePageOpen(false), title: "Archive space", btnProps: {
                title: 'Archive space',
                onClick: onDeleteSpace,
                isLoading: appData.process,
            }, onClose: () => setIsDeletePageOpen(false) }));
    }
    if (currentStep === 1) {
        return (_jsxs(_Fragment, { children: [_jsx(Header, { spaceMenuItems: menuItems, spaceMenu: created, onBackBtnClick: () => {
                        const pathSegments = window.location.pathname.split('/').filter(Boolean);
                        const parentPath = '/' + pathSegments.slice(0, -1).join('/');
                        navigate(parentPath);
                        spaceData.clean();
                    }, title: getSpaceTitle(), subtitle: spaceData.isEdit ? `Step ${currentStep} of 2` : '' }), _jsxs(BlockContainer, { children: [_jsx(ItemsBlock, {}), _jsx(MediaBlock, {}), _jsx(CommentBlock, {}), isEditable() && (_jsx(Button, { disabled: appData.loading, full: true, type: "submit", onClick: () => setCurrentStep(2), kind: ButtonKinds.primary, children: "Next" }))] }), _jsx(TransferSpace, { transferring: appData.process, onTransfer: onTransferSpace, open: isTransferModalOpen, onClose: () => setIsTransferModalOpen(false) }), isHistoryPageOpen && (_jsx(TimeLineModal, { items: data?.history || [], onClose: () => setIsHistoryPageOpen(false), title: "Space timeline" }))] }));
    }
    if (currentStep === 2) {
        return (_jsx(SavingPage, { onBackClick: () => setCurrentStep(1), title: getSpaceTitle(), subtitle: `Step ${currentStep} of 2`, btnProps: {
                title: 'Save',
                onClick: data => onAddSpace(data),
                isLoading: appData.process,
            }, onClose: () => setCurrentStep(1) }));
    }
    return _jsx("p", { children: "There is nothing for now" });
};
export default observer(AddSpace);
