import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockWithIcon } from '#Components/shared';
import { ChatIcon, TrashBin } from '#Images';
import useStores from '#Hooks/useStores';
import { observer } from 'mobx-react-lite';
import { TextArea } from '#Components/CommonStyled';
import styled from 'styled-components';
import { DesignFonts } from '../../../style/DesignFonts';
import dayjs from 'dayjs';
export const Reminder = styled.span `
  color: var(--main-gray-dark);
  ${DesignFonts.smallPrint}
`;
export const CommentItemBlock = styled.div `
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
`;
export const CommentItem = styled.div `
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  padding: 6px 10px;
  border-radius: 8px;
  ${DesignFonts.smallPrint}
  background-color: var(--main-light-gray);

  img {
    float: right;
    cursor: pointer;
  }

  span {
    color: var(--main-gray-dark);
    margin-right: 6px;
  }
`;
const CommentBlock = () => {
    const { spaceData } = useStores();
    const symbolsLeft = 250 - spaceData.comment.length;
    const onSetComment = (e) => {
        const val = e.target.value;
        spaceData.setComment(val);
    };
    return (_jsxs(BlockWithIcon, { iconUrl: ChatIcon, title: "Comments", children: [!!spaceData.comments.length && (_jsx(CommentItemBlock, { children: spaceData.comments.map((comment, index) => (_jsxs(CommentItem, { children: [_jsx("div", { children: _jsxs("div", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx("span", { children: dayjs(comment.created_at).format('DD.MM.YYYY HH:mm') }), _jsxs("div", { children: [" ", comment.content] })] }) }), spaceData.isEdit && (_jsx("img", { onClick: () => spaceData.removeComment(comment.id), role: "button", src: TrashBin, alt: "trash-bin-icon" }))] }, index))) })), _jsx(TextArea, { style: { height: '100px' }, value: spaceData.comment, onChange: onSetComment, disabled: !spaceData.isEdit }), _jsxs(Reminder, { children: ["Remaining: ", symbolsLeft, " characters."] })] }));
};
export default observer(CommentBlock);
