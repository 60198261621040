import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockWithIcon } from '#Components/shared';
import { ChatIcon } from '#Images';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { CommentItem, CommentItemBlock } from '#Components/AddSpace/components/CommentBlock';
const ClientCommentBlock = ({ comments }) => {
    return (_jsx(BlockWithIcon, { iconUrl: ChatIcon, title: "Comments", children: !!comments.length ? (_jsx(CommentItemBlock, { children: comments.map((comment, index) => (_jsx(CommentItem, { children: _jsx("div", { children: _jsxs("div", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx("span", { children: dayjs(comment.created_at).format('DD.MM.YYYY HH:mm') }), _jsxs("div", { children: [" ", comment.content] })] }) }) }, index))) })) : (_jsx("p", { children: "No comments yet" })) }));
};
export default observer(ClientCommentBlock);
