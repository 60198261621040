import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextArea } from '#Components/CommonStyled';
import { Document, DocumentOrange } from '#Images';
import { useState } from 'react';
import styled from 'styled-components';
const ItemContainer = styled.div `
  font-weight: 400;
  font-size: 16px;
  /* padding: 8px 0; */
  height: 48px;
  line-height: 22px;
  justify-content: space-between;
  display: flex;
  gap: 32px;

  align-items: center;
  border-bottom: none;

  ${({ $inModal }) => $inModal &&
    `
    :not(:last-child) {
    border-bottom: 1px solid #e6ebef;
    height: 56px;
    }

  `}
`;
const PlusBtn = styled.div `
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 12px;
    background-color: var(--main-black);
  }
  ::before {
    width: 12px;
    height: 2px;
  }
`;
const ItemBtn = styled.span `
  width: 32px;
  height: 32px;
  margin: 0 4px;
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--main-black);
`;
const BoxInput = styled.input `
  width: 56px;
  height: 40px;
  padding: 12px;
  border-radius: 12px;
  font-size: 16px;
  border: none;
  text-align: center;
  background: transparent;
  background-color: var(--main-light-gray);
`;
const AddCommentBtn = styled.button `
  border: none;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const ControllerContainer = styled.div `
  display: flex;
  align-items: center;
  background-color: ${({ $disabled }) => ($disabled ? 'transparent' : 'var(--main-light-gray)')};
  border-radius: 12px;
`;
const AddBtn = styled.button `
  font-weight: 600;
  font-size: 16px;
  padding: 11px;
`;
const LeftItemBlock = styled.div `
  display: flex;
  align-items: center;
`;
const ItemWithComment = ({ maxAmount, amount, title, onChange, onSelect, inModal, comment, setComment, }) => {
    const [showComment, setShowComment] = useState(false);
    const onIncrease = () => {
        console.log(amount, maxAmount);
        if (amount + 1 > maxAmount) {
            return;
        }
        onChange(String(amount + 1));
    };
    const onDecrease = () => {
        if (amount - 1 < 0) {
            return;
        }
        onChange(String(amount - 1));
    };
    const onSetInputValue = (v) => {
        if (!v) {
            return onChange('0');
        }
        onChange(v);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ItemContainer, { "$inModal": inModal, children: [_jsxs(LeftItemBlock, { children: [_jsx(AddCommentBtn, { onClick: () => setShowComment(!showComment), children: showComment ? (_jsx("img", { width: 16, height: 16, src: DocumentOrange, alt: "document orange icon" })) : (_jsx("img", { width: 16, height: 16, src: Document, alt: "document icon" })) }), _jsx("p", { children: title })] }), _jsxs(ControllerContainer, { "$disabled": false, children: [_jsx(ItemBtn, { onClick: onDecrease, children: "\u2014" }), _jsx(BoxInput, { style: { fontWeight: 600 }, disabled: false, onChange: v => onSetInputValue(v.target.value), value: amount }), _jsx(ItemBtn, { onClick: onIncrease, children: _jsx(PlusBtn, {}) })] })] }), showComment && _jsx(TextArea, { placeholder: "Add comment", value: comment, onChange: e => setComment(e.target.value) })] }));
};
export default ItemWithComment;
