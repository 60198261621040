import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Client from 'getaddress-api';
import { useEffect, useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { TEST_KEY } from './apiKeys';
import { Container, SuggestionRow, SuggestionsContainer, SuggestionsContentContainer } from '../../../shared/Dropdown';
import { useDebounce } from '#Hooks';
import { FormInput } from '#Components/shared';
import { MapSmall } from '#Images';
const api = new Client(TEST_KEY);
const GetAddress = ({ defaultValue, onChange, description, title, isDisabled, errorText }) => {
    const [suggestions, setSuggestions] = useState([]);
    const [debouncedInputVal, inputValue, setInputValue] = useDebounce('', 500);
    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);
    const ref = useOnclickOutside(() => {
        setSuggestions([]);
    });
    const isSet = useRef(false);
    const asyncSearch = async (val) => {
        const autocompleteResult = await api.autocomplete(val);
        if (autocompleteResult.isSuccess) {
            const success = autocompleteResult.toSuccess();
            setSuggestions(success.suggestions);
        }
        else {
            const failed = autocompleteResult.toFailed();
            console.log(failed);
        }
    };
    const handleInput = (v) => {
        setInputValue(v);
        isSet.current = false;
    };
    useEffect(() => {
        if (defaultValue !== debouncedInputVal && !isSet.current) {
            asyncSearch(debouncedInputVal);
        }
    }, [debouncedInputVal]);
    const handleSelect = (addr, id) => async () => {
        isSet.current = true;
        setInputValue(addr);
        setSuggestions([]);
        const addressResult = await api.get(id);
        if (addressResult.isSuccess) {
            const res = addressResult.toSuccess();
            onChange({
                address: res.address.formatted_address.join(' ').trim(),
                postcode: res.address.postcode,
                coords: { lat: res.address.latitude, lng: res.address.longitude },
            });
        }
        else {
            const failed = addressResult.toFailed();
            onChange({ address: addr, postcode: '', coords: { lat: null, lng: null } });
            console.log(failed);
        }
    };
    const renderSuggestions = () => suggestions.map(suggestion => {
        const { address, id } = suggestion;
        return (_jsx(SuggestionRow, { onClick: handleSelect(address, id), children: address }, id));
    });
    return (_jsxs(Container, { ref: ref, children: [_jsx(FormInput, { errorText: errorText, name: title || '', onChange: handleInput, value: inputValue, description: description, iconUrl: MapSmall, disabled: isDisabled }), !!suggestions.length && (_jsx(SuggestionsContainer, { children: _jsx(SuggestionsContentContainer, { children: renderSuggestions() }) }))] }));
};
export default GetAddress;
