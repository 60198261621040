import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import SwipeContainer from '../SwipeContainer';
import { FormInput } from '#Components/shared';
import { BlockTitle } from '#Components/CommonStyled';
import styled from 'styled-components';
import { DesignFonts } from '../../../style/DesignFonts';
import Loader from '#Components/shared/Loader';
import { useParams } from 'react-router-dom';
import { ApiService } from '#Services';
import { useToast } from '../../../providers/ToastProvider';
const api = new ApiService();
const HeaderButtons = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderButton = styled.button `
  background: none;
  border: none;
  cursor: pointer;

  ${DesignFonts.bodyDemiBold}
  :disabled {
    color: var(--main-gray);
  }

  ${props => props.$active &&
    `
        color: var(--main-color);
    `}
`;
const RenameUnit = ({ onClose, open, afterRename }) => {
    const { addToast } = useToast();
    const [newName, setNewName] = useState('');
    const [newSealNumber, setNewSealNumber] = useState('');
    const { unitId, storageId } = useParams();
    const [updating, setUpdating] = useState(false);
    const onChangeUnit = async () => {
        try {
            setUpdating(true);
            await api.patchUnit(Number(unitId), { title: newName, ...(newSealNumber && { seal_number: newSealNumber }) });
            addToast &&
                addToast({
                    message: 'Unit successfully renamed',
                    type: 'success',
                });
            afterRename();
        }
        catch (error) {
            addToast &&
                addToast({
                    message: 'Something went wrong',
                    type: 'error',
                });
        }
        finally {
            setUpdating(false);
        }
    };
    return (_jsx(_Fragment, { children: _jsx(SwipeContainer, { opened: open, onClose: onClose, children: _jsxs("div", { id: "transfer_unit_list", style: { display: 'flex', flexDirection: 'column', minHeight: '80vh' }, children: [_jsxs(HeaderButtons, { children: [_jsx(HeaderButton, { onClick: onClose, children: "Cancel" }), _jsx(HeaderButton, { disabled: !newName.length && !newSealNumber.length, 
                                // $active={!!selectedUnitId && !transferring}
                                onClick: onChangeUnit, children: "Save" })] }), _jsx(BlockTitle, { children: "You can change unit name or seal number" }), _jsx(FormInput, { onClear: () => setNewName(''), bottomMargin: 10, name: 'New name', onChange: setNewName, value: newName }), _jsx(FormInput, { onClear: () => setNewSealNumber(''), bottomMargin: 10, name: 'New Seal number', onChange: setNewSealNumber, value: newSealNumber }), updating && _jsx(Loader, {})] }) }) }));
};
export default RenameUnit;
