import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ButtonText, Spinner, StyledButton } from './Components';
export var ButtonKinds;
(function (ButtonKinds) {
    ButtonKinds["primary"] = "primary";
    ButtonKinds["secondary"] = "secondary";
})(ButtonKinds || (ButtonKinds = {}));
export const Button = React.forwardRef((props, ref) => {
    return (_jsxs(StyledButton, { disabled: props.loading || props.disabled, ...props, children: [_jsx(ButtonText, { loading: props.loading, children: props.children }), props.loading && (_jsx(Spinner, { width: 42, height: 42, viewBox: "0 0 50 50", children: _jsx("circle", { className: "path", cx: "25", cy: "25", r: "20", fill: "none", strokeWidth: "5" }) }))] }));
});
export default Button;
