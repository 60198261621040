import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormInput, Header } from '#Components';
import { SearchListItem } from '#Components/CommonStyled';
import Loader from '#Components/shared/Loader';
import { useDebounce, useErrorToastEffect, useStores } from '#Hooks';
import { ApiService } from '#Services';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const api = new ApiService();
const Clients = () => {
    const { appData } = useStores();
    const [debouncedFilter, filter, setFilter] = useDebounce('', 700);
    const navigate = useNavigate();
    const { data, error, isLoading, refetch } = useQuery({
        enabled: filter.length >= 3,
        queryKey: ['clients'],
        queryFn: () => api.getClients(filter),
    });
    useErrorToastEffect(error, 'Error with unit loading');
    useEffect(() => {
        if (debouncedFilter.length >= 3) {
            refetch();
        }
    }, [debouncedFilter]);
    useEffect(() => {
        if (!appData.storagePlans.length) {
            appData.fetchStoragePlans();
        }
    }, []);
    const onClientClick = (id) => {
        navigate(`/clients/${id}`);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, { title: "Clients" }), _jsx(FormInput, { onClear: () => setFilter(''), name: "Search by name or ID", onChange: setFilter, value: filter }), isLoading ? (_jsx(Loader, {})) : (_jsx("div", { style: { marginTop: 20 }, children: data?.map(client => (_jsxs(SearchListItem, { onClick: () => onClientClick(`${client.id}`), children: [_jsxs("span", { children: [client.first_name, " ", client.last_name] }), _jsxs("p", { children: [client.space_ids.length, " spaces"] })] }, client.id))) }))] }));
};
export default observer(Clients);
