import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useContext, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
const ToastContainer = styled.div `
  width: 100%;
`;
const Toast = styled.div `
  background-color: ${({ $type }) => ($type === 'error' ? 'var(--main-color)' : 'rgba(27, 27, 30, 1)')};
  color: white;
  padding: 16px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 7px 14px 0px rgba(60, 66, 87, 0.08);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  max-width: 360px;
  margin-top: 10px;
`;
const ToastContext = createContext({});
export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);
    const addToast = useCallback((toast) => {
        const id = Date.now().toString();
        const newToast = { ...toast, id };
        setToasts(prevToasts => [...prevToasts, newToast]);
        setTimeout(() => {
            removeToast(newToast);
        }, 2500); //
    }, []);
    const removeToast = useCallback((toastToRemove) => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.id !== toastToRemove.id));
    }, []);
    return (_jsxs(ToastContext.Provider, { value: { addToast, removeToast }, children: [children, ReactDOM.createPortal(_jsx(ToastContainer, { children: toasts.map((toast, index) => (_jsx(Toast, { onClick: () => removeToast(toast), "$type": toast.type, className: "toast-message", children: toast.message }, toast.id))) }), document.getElementById('toasts') || document.body)] }));
};
export const useToast = () => {
    return useContext(ToastContext);
};
