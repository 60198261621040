import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import ApiService from '#Services/apiService';
import Loader from '#Components/shared/Loader';
import LoginForm from '#Components/LoginForm';
const api = new ApiService();
const AuthContext = createContext(undefined);
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    // const [isAuth, setIsAuth] = useState(false)
    const [loading, setLoading] = useState(true);
    const [logging, setLogging] = useState(false);
    const [error, setError] = useState(false);
    const checkUser = async () => {
        try {
            setError(false);
            const user = await api.checkUser();
            if (user.data) {
                setUser(user.data);
            }
        }
        catch (error) {
            console.error(error);
            setError(true);
        }
        finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        checkUser();
    }, []);
    const signIn = useCallback(async (credentials, callback) => {
        try {
            setLogging(true);
            setError(false);
            await api.login(credentials.username, credentials.password);
            const user = await api.checkUser();
            setUser(user.data);
            callback?.();
        }
        catch (error) {
            console.error(error);
            setError(true);
        }
        finally {
            setLogging(false);
        }
    }, []);
    const signOut = useCallback(async (callback) => {
        try {
            setError(false);
            await api.logout();
            setUser(null);
            callback?.();
        }
        catch (error) {
            console.error(error);
            setError(true);
        }
        finally {
            setLoading(false);
        }
    }, []);
    const value = { user, signIn, signOut };
    if (loading) {
        return _jsx(Loader, {});
    }
    if (!user) {
        return _jsx(LoginForm, { loggingIn: logging, signIn: signIn, error: error });
    }
    return _jsx(AuthContext.Provider, { value: value, children: children });
};
export default AuthProvider;
