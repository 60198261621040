import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import DatePicker from '#Components/shared/DatePicker/DatePicker';
import { useState } from 'react';
import { Button } from '#Components/shared';
import { ButtonKinds } from '#Components/shared/Button/Button';
const MobileDatePicker = ({ onChange, value }) => {
    const [date, setDate] = useState(value);
    const onSelectClick = () => {
        onChange(date);
    };
    return (_jsxs(_Fragment, { children: [_jsx(DatePicker, { onChange: setDate, value: date }), _jsx("div", { style: { marginTop: '40px' }, children: _jsx(Button, { full: true, type: "submit", onClick: onSelectClick, kind: ButtonKinds.primary, children: "Select" }) })] }));
};
export default MobileDatePicker;
