import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
const ItemContainer = styled.div `
  font-weight: 400;
  font-size: 16px;
  /* padding: 8px 0; */
  height: 48px;
  line-height: 22px;
  justify-content: space-between;
  display: flex;
  gap: 32px;

  align-items: center;
  border-bottom: none;

  ${({ $inModal }) => $inModal &&
    `
    :not(:last-child) {
    border-bottom: 1px solid #e6ebef;
    height: 56px;
    }

  `}
`;
const PlusBtn = styled.div `
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 12px;
    background-color: var(--main-black);
  }
  ::before {
    width: 12px;
    height: 2px;
  }
`;
const ItemBtn = styled.span `
  width: 32px;
  height: 32px;
  margin: 0 4px;
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--main-black);
`;
const BoxInput = styled.input `
  width: 56px;
  height: 40px;
  padding: 12px;
  border-radius: 12px;
  font-size: 16px;
  border: none;
  text-align: center;
  background: transparent;
  background-color: var(--main-light-gray);
`;
const RemoveItemBtn = styled.button `
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: #f04438;
  color: #fff;
`;
const ControllerContainer = styled.div `
  display: flex;
  align-items: center;
  background-color: ${({ $disabled }) => ($disabled ? 'transparent' : 'var(--main-light-gray)')};
  border-radius: 12px;
`;
const AddBtn = styled.button `
  font-weight: 600;
  font-size: 16px;
  padding: 11px;
`;
const LeftItemBlock = styled.div `
  display: flex;
  align-items: center;

  button {
    margin-right: 8px;
  }
`;
const CartItem = ({ amount, title, onChange, onRemove, edit, isSelected, onSelect, inModal }) => {
    const onIncrease = () => {
        onChange(String(Number(amount) + 1));
    };
    const onDecrease = () => {
        if (amount - 1 <= 0) {
            return onRemove();
        }
        onChange(String(Number(amount) - 1));
    };
    const onSetInputValue = (v) => {
        if (!v) {
            return onChange('0');
        }
        onChange(v);
    };
    return (_jsxs(ItemContainer, { "$inModal": inModal, children: [_jsxs(LeftItemBlock, { children: [edit && !onSelect && _jsx(RemoveItemBtn, { onClick: onRemove, children: "\u2014" }), _jsx("p", { children: title })] }), !isSelected && onSelect ? (_jsx(AddBtn, { onClick: onSelect, children: "Add" })) : (_jsxs(ControllerContainer, { "$disabled": !edit && !onSelect, children: [edit && _jsx(ItemBtn, { onClick: onDecrease, children: "\u2014" }), _jsx(BoxInput, { style: { fontWeight: 600 }, disabled: !edit, onChange: v => onSetInputValue(v.target.value), value: amount }), edit && (_jsx(ItemBtn, { onClick: onIncrease, children: _jsx(PlusBtn, {}) }))] }))] }));
};
export default CartItem;
