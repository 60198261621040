import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ImageContainer } from '#Components/AddSpace/components/MediaBlock';
import { useParams } from 'react-router-dom';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
const TimelineContainer = styled.div `
  width: 100%;
  position: relative;
  overflow-y: auto;
`;
const TimelineComponent = styled.ul `
  list-style: none;
  padding: 0;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 8px; /* Подгоните это значение, чтобы линия была в нужном месте */
    top: 0;
    bottom: 0;
    width: 6px;
    background-color: var(--main-light-gray);
    z-index: 1;
  }
`;
const TimelineEvent = styled.li `
  display: flex;
  /* align-items: flex-start; */
  margin-bottom: 20px;
  position: relative;
`;
const TimelineEventIcon = styled.div `
  width: 20px;
  height: 20px;
  background-color: var(--main-light-gray);
  border-radius: 50%;
  z-index: 2; /* Убедитесь, что иконки находятся над линией */
  position: absolute;
  left: 1px; /* Подгоните это значение, чтобы иконка была в нужном месте относительно линии */
  top: 0; /* Верхняя точка иконки выровнена с верхним краем события */
`;
const TimelineEventContent = styled.div `
  margin-left: 40px; /* Отрегулируйте если нужно больше места для иконки */
  /* padding: 10px; */
  /* background-color: #f3f3f3; */
  border-radius: 3px;
  z-index: 2; /* Элементы контента должны быть поверх линии */
`;
const TimelineEventTime = styled.time `
  margin: 0;
  color: #999;
  ${DesignFonts.smallPrint};
  /* margin-bottom: 5px; */
`;
const ItemsList = styled.ul `
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    ${({ $removed }) => $removed && ` text-decoration: line-through;`}
  }
`;
const TimelineEventDescription = styled.p `
  margin: 0;
`;
const TimelineHeaderContainer = styled.div `
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;
const ContentSection = styled.div `
  display: flex;
  flex-direction: column;
  & > span {
    color: var(--main-gray-dark);
    margin-bottom: 5px;
  }
  margin-bottom: 8px;
`;
const CommentItem = styled.div `
  background-color: var(--main-light-gray);
  padding: 4px 8px;
  border-radius: 8px;

  ${({ $removed }) => $removed && ` text-decoration: line-through;`}

  :not(:last-child) {
    margin-bottom: 8px;
  }
`;
const getEventTitle = (event) => {
    switch (event) {
        case 'CREATE':
            return 'created';
        case 'UPDATE':
            return 'edited';
        case 'DELETE':
            return 'deleted';
    }
};
const getSectionContent = (data, type) => {
    if (!data) {
        return null;
    }
    const getTitle = () => {
        switch (type) {
            case 'added':
                return 'Added';
            case 'removed':
                return 'Removed';
        }
    };
    return (_jsxs(_Fragment, { children: [!!data.items.length && (_jsxs(ContentSection, { children: [_jsxs("span", { children: [getTitle(), " ", data.items.length, " items"] }), _jsx(ItemsList, { "$removed": type === 'removed', children: data.items.map(item => (_jsxs("li", { children: [item.title, " x ", item.amount] }, item.id))) })] })), !!data.media.length && (_jsxs(ContentSection, { children: [_jsxs("span", { children: [getTitle(), " ", data.media.length, " media"] }), _jsx(BlockContainer, { children: data.media.map(mediaItem => (_jsx(_Fragment, { children: _jsx(ImageContainer, { "$disabled": true, children: _jsx("img", { src: mediaItem.content_type.includes('image') ? mediaItem.url : mediaItem.thumbnail?.url, alt: 'img' }) }, mediaItem.id) }))) })] })), !!data.comments.length && (_jsxs(ContentSection, { children: [_jsxs("span", { children: [getTitle(), " comments"] }), data.comments.map((comment, index) => (_jsx(CommentItem, { "$removed": type === 'removed', children: comment.content }, comment.id)))] }))] }));
};
const Timeline = ({ events }) => {
    const { spaceId = '' } = useParams();
    return (_jsx(TimelineContainer, { children: _jsx(TimelineComponent, { children: events.map((event, index) => (_jsxs(TimelineEvent, { children: [_jsx(TimelineEventIcon, {}), _jsxs(TimelineEventContent, { children: [_jsxs(TimelineHeaderContainer, { children: [_jsxs("span", { children: ["Space ", _jsx("span", { style: { fontWeight: 'bold' }, children: spaceId }), " was ", getEventTitle(event.type), "."] }), _jsxs(TimelineEventTime, { children: [event.date, " Order ID ", event.order_id] })] }), getSectionContent(event.changes.added, 'added'), getSectionContent(event.changes.removed, 'removed')] })] }, index))) }) }));
};
export default Timeline;
