import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
import { getSpaceName } from '#Utils/helpers';
import { useNavigate } from 'react-router-dom';
import { ArrowDown } from '#Images';
const ViewSpaceBtn = styled.button `
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  img {
    width: 9px;
    height: 10px;
    margin-left: 10px;
    transform: rotate(-90deg);
  }
`;
const Space = styled.h2 `
  ${DesignFonts.bodyDemiBold};
`;
const Unit = styled.span `
  ${DesignFonts.bodyRegular};
`;
const SpaceInfoLink = ({ space, onClick }) => {
    const navigate = useNavigate();
    const onViewSpaceClick = () => {
        if (onClick) {
            return onClick();
        }
        navigate(`/storage/${space.storage_id}/${space.unit_id}/${space.id}`);
    };
    const totalItems = space.items.reduce((acc, item) => acc + item.amount, 0);
    return (_jsxs("div", { onClick: onViewSpaceClick, style: { display: 'flex', justifyContent: 'space-between', marginBottom: 20, cursor: 'pointer' }, children: [_jsxs("div", { style: { display: 'flex', flexDirection: 'column' }, children: [_jsx(Space, { children: getSpaceName(space) }), _jsxs(Unit, { children: [space.storage_title, " \u2022 Unit ", space.unit_title, " \u2022 Total items: ", totalItems] })] }), _jsx(ViewSpaceBtn, { children: _jsx("img", { src: ArrowDown }) })] }));
};
export default SpaceInfoLink;
