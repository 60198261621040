import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useStores } from '#Hooks';
import { useEffect } from 'react';
import ItemWithComment from '../shared/ItemWithComment';
import { testClientInfo } from '#Constants/testData';
import { observer } from 'mobx-react-lite';
import { Button } from '#Components/shared';
import { BottomButtonsWrapper } from '../shared/components';
const Items = () => {
    const { storageReturnData: { items, setItems, updateItem, clientInfo, setClientInfo, setCurrentStepIndex }, spaceData, } = useStores();
    const onNextClick = () => {
        setCurrentStepIndex(2);
    };
    useEffect(() => {
        if (!clientInfo) {
            setClientInfo(testClientInfo);
        }
    }, []);
    useEffect(() => {
        if (!items.length) {
            setItems(clientInfo?.items.map(i => ({ ...i, comment: '', max_amount: i.amount })) || []);
        }
    }, [clientInfo]);
    return (_jsxs(_Fragment, { children: [items.map((item, index) => (_createElement(ItemWithComment, { maxAmount: item.max_amount, setComment: v => updateItem({ ...item, comment: v }), onChange: v => updateItem({ ...item, amount: Number(v) }), ...item, key: index }))), _jsx(BottomButtonsWrapper, { children: _jsx(Button, { onClick: onNextClick, full: true, 
                    // $mobileSmall
                    kind: 'primary', loading: false, type: "button", children: "Continue" }) })] }));
};
export default observer(Items);
