import { jsx as _jsx } from "react/jsx-runtime";
import './scss/index.scss';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '#Components/ErrorBoundary/ErrorBoundary';
import { ToastProvider } from './providers/ToastProvider';
import AuthProvider from './providers/AuthProvider';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(React.StrictMode, { children: _jsx(ErrorBoundary, { children: _jsx(AuthProvider, { children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(BrowserRouter, { children: _jsx(ToastProvider, { children: _jsx(App, {}) }) }) }) }) }) }));
