import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.scss';
import ReactDatePicker from 'react-datepicker';
export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const DatePicker = ({ value, onChange }) => (_jsx(ReactDatePicker, { disabledKeyboardNavigation: true, renderCustomHeader: ({ decreaseMonth, increaseMonth, date, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
        const curMonth = new Date(date).getMonth();
        const curYear = new Date(date).getFullYear();
        return (_jsxs("div", { className: "datepicker-header", children: [_jsx("button", { "aria-label": "btn-left", type: "button", className: "month-changer month-changer_left", onClick: decreaseMonth, disabled: prevMonthButtonDisabled }), _jsxs("div", { className: "datepicker-header__month", children: [months[curMonth], " ", _jsx("span", { className: "datepicker-header__year", children: curYear })] }), _jsx("button", { "aria-label": "btn-right", type: "button", className: "month-changer month-changer_right", onClick: increaseMonth, disabled: nextMonthButtonDisabled })] }));
    }, 
    // temp fix for no default value
    selected: value || '', className: "datepicker-calendar", inline: true, calendarClassName: "datepicker-body", dayClassName: date => 'datepicker-days', onChange: date => onChange(date), minDate: null }));
export default DatePicker;
