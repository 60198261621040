import { jsx as _jsx } from "react/jsx-runtime";
import IconBtnBLock from '#Components/shared/IconBtnBLock';
import useStores from '#Hooks/useStores';
import { UploadIcon } from '#Images';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}
const StyledDropzone = ({ onSetImages, lined }) => {
    const { spaceData } = useStores();
    const onDropFiles = useCallback((acceptedFiles) => {
        onSetImages(acceptedFiles);
        // acceptedFiles.forEach(file => {
        // fileToBase64(file).then((base64: string) => {
        //   onSetImages(base64);
        // });
        // });
    }, []);
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: { 'image/*': [], 'video/*': [] },
        onDrop: onDropFiles,
    });
    return (_jsx(IconBtnBLock, { lined: lined, disabled: !spaceData.isEdit, iconUrl: UploadIcon, title: "Upload", ...getRootProps({ isFocused, isDragAccept, isDragReject }), children: _jsx("input", { ...getInputProps() }) }));
};
export default StyledDropzone;
