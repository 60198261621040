import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
import { ArrowLink } from '#Images';
export const BlockContainer = styled.div `
  display: flex;
  gap: ${({ $gap }) => ($gap ? `${$gap}px` : '12px')};
  flex-wrap: wrap;
`;
export const BLock = styled.div `
  width: ${({ $size }) => ($size === 'small' ? '114px' : '173px')};
  height: ${({ $size }) => ($size === 'small' ? '114px' : '173px')};
  border-radius: ${({ $size }) => ($size === 'small' ? '22px' : '32px')};
  background-color: var(--main-light-gray);
  padding: ${({ $size }) => ($size === 'small' ? '12px' : '20px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`;
const BLockTitle = styled.div `
  max-width: 64px;
  ${({ $size }) => ($size === 'small' ? DesignFonts.secondary : DesignFonts.bodyDemiBold)};
`;
const BLockHeader = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const UnitBlock = ({ linkTo, title, size, children }) => {
    return (_jsx(Link, { to: linkTo, children: _jsxs(BLock, { "$size": size, children: [_jsxs(BLockHeader, { children: [_jsxs(BLockTitle, { "$size": size, children: [" ", title] }), _jsx("img", { width: size === 'small' ? 16 : 20, height: size === 'small' ? 16 : 20, src: ArrowLink, alt: "add" })] }), children] }) }));
};
export default UnitBlock;
