import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormContainer, FormWrapper } from './Components';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { Button, FormInput } from '#Components/shared';
const LoginForm = ({ signIn, loggingIn, error }) => {
    const FormSchema = object().shape({
        email: string().required('Field is required'),
        password: string().required('Field is required'),
    });
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: FormSchema,
        onSubmit: async (values, actions) => {
            signIn({ username: values.email, password: values.password });
        },
    });
    return (_jsx(FormWrapper, { children: _jsxs(FormContainer, { onSubmit: formik.handleSubmit, id: "login_form_1", children: [_jsx("h5", { children: "Sign in to Stackt Storage" }), _jsx(FormInput, { name: "Work Email", onChange: formik.handleChange('email'), value: formik.values.email, type: "email", errorText: formik.errors.email, bottomMargin: 8 }), _jsx(FormInput, { name: "Password", onChange: formik.handleChange('password'), value: formik.values.password, type: "password", errorText: error ? 'Incorrect email or password' : formik.errors.password, bottomMargin: 20 }), _jsx(Button, { kind: "primary", loading: loggingIn, type: "submit", full: true, children: "Log in" })] }) }));
};
export default LoginForm;
