import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TextArea } from '#Components/CommonStyled';
import { Button, FormInput } from '#Components/shared';
import { ColumnFlexContainer } from '../shared/total-sheet/components';
import { BottomButtonsWrapper } from '../shared/components';
import { useErrorToastEffect, useStores } from '#Hooks';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
const Details = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const { storageReturnData: { setContactData, contactData, createOrder }, appData: { process, error }, } = useStores();
    const onConfirmClick = async () => {
        const hubSpotId = await createOrder(Number(clientId));
        if (hubSpotId) {
            navigate(`/clients/${clientId}/return/${hubSpotId}`);
        }
    };
    useErrorToastEffect(error, 'Error with order creating');
    return (_jsxs(_Fragment, { children: [_jsxs(ColumnFlexContainer, { children: [_jsx(FormInput, { errorText: '', name: 'Email', onChange: v => setContactData({ ...contactData, email: v }), value: contactData.email }), _jsx(FormInput, { errorText: '', name: 'Phone', onChange: v => setContactData({ ...contactData, phone: v }), value: contactData.phone }), _jsx(TextArea, { value: contactData.comment, placeholder: "Comments", onChange: e => setContactData({ ...contactData, comment: e.target.value }), rows: 6 })] }), _jsx(BottomButtonsWrapper, { children: _jsx(Button, { onClick: onConfirmClick, full: true, kind: 'primary', loading: process, type: "button", children: "Confirm" }) })] }));
};
export default observer(Details);
