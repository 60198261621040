import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Header from '#Components/Header/Header';
import { ClockIcon } from '#Images';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import TimeLineModal from '#Components/TimeLine/TimeLineModal';
import { getSpaceName } from '#Utils/helpers';
import ItemsBlock from '#Components/AddSpace/components/ItemsBlock';
import MediaBlock from '#Components/AddSpace/components/MediaBlock';
import CommentBlock from '#Components/AddSpace/components/CommentBlock';
const ArchivedSpace = ({ space, onClose }) => {
    const [isHistoryPageOpen, setIsHistoryPageOpen] = useState(false);
    const menuItems = [
        {
            title: 'View timeline',
            iconUrl: ClockIcon,
            onClick: () => setIsHistoryPageOpen(true),
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(Header, { spaceMenuItems: menuItems, spaceMenu: true, onBackBtnClick: onClose, title: getSpaceName(space) }), _jsx("h4", { style: { color: 'var(--main-color)', textAlign: 'center', fontWeight: 600, fontSize: 24, marginTop: 10 }, children: "Archived space" }), _jsxs(BlockContainer, { children: [_jsx(ItemsBlock, {}), _jsx(MediaBlock, {}), _jsx(CommentBlock, {})] }), isHistoryPageOpen && (_jsx(TimeLineModal, { items: space?.history || [], onClose: () => setIsHistoryPageOpen(false), title: "Space timeline" }))] }));
};
export default ArchivedSpace;
