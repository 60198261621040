import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import SwipeContainer from '../SwipeContainer';
import MobileDatePicker from './MobileDatePicker';
import { FormInput } from '#Components/shared';
import dayjs from 'dayjs';
const DatePickerBlock = ({ date, onSelectDate, title, disabled }) => {
    const [dateSwiperOpen, setDateSwiperOpen] = useState(false);
    const onOpenContainer = () => {
        if (disabled) {
            return;
        }
        setDateSwiperOpen(true);
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormInput, { disabled: disabled, readonly: true, onFocus: onOpenContainer, name: title, onChange: () => { }, value: date ? dayjs(date).format('DD-MM-YYYY') : '', bottomMargin: 12 }), _jsx(SwipeContainer, { onClose: () => setDateSwiperOpen(false), needCloseBtn: true, opened: dateSwiperOpen, children: _jsx(MobileDatePicker, { onChange: d => {
                        onSelectDate(d || new Date());
                        setDateSwiperOpen(false);
                    }, value: date }) })] }));
};
export default DatePickerBlock;
