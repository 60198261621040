import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Header } from '#Components';
import { BlockContainer } from '#Components/UnitBlock/UnitBlock';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from '#Services';
import { StorageItemLoader } from '#Components/AddSpace/components/skeletons';
import { createEmptyArray } from '#Utils/helpers';
import { useErrorToastEffect } from '#Hooks/useErrorToastEffect';
import { StorageBLockTitle, StorageBlock } from '#Components/AddSpace/components/StorageBlock';
import { Link } from 'react-router-dom';
import { ArrowLink } from '#Images';
const api = new ApiService();
const Storages = () => {
    const { data, error, isLoading } = useQuery({
        queryKey: ['storages'],
        queryFn: api.getStorages,
    });
    useErrorToastEffect(error, 'Error with storages loading');
    return (_jsxs(_Fragment, { children: [_jsx(Header, { title: "Storages" }), _jsx(BlockContainer, { children: isLoading ? (createEmptyArray(3).map((item, index) => _jsx(StorageItemLoader, {}, `empty_${index}`))) : (_jsx(_Fragment, { children: data?.map(storage => (_jsx(Link, { to: `/storage/${storage.id}`, children: _jsxs(StorageBlock, { children: [_jsx("img", { style: { position: 'absolute', right: 20, top: 20 }, width: 20, height: 20, src: ArrowLink, alt: "add" }), _jsx(StorageBLockTitle, { children: storage.title })] }) }, storage.id))) })) })] }));
};
export default Storages;
