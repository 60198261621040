import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import GetAddress from '../shared/GetAddress/GetAddress';
import Combobox from '#Components/shared/Combobox';
import ToggleRow from '#Components/shared/ToggleRow/ToggleRow';
import { ElevatorIcon, ParkingIcon } from '#Images';
import { useStores } from '#Hooks';
import { Button } from '#Components/shared';
import { observer } from 'mobx-react-lite';
import { BottomButtonsWrapper } from '../shared/components';
const floorItems = Array.from({ length: 88 }, (_, i) => String(i));
const Address = () => {
    const [isEmptyFloorError, setIsEmptyFloorError] = useState(false);
    const [isEmptyAddressError, setIsEmptyAddressError] = useState(false);
    const { storageReturnData: { address, setAddress, setCurrentStepIndex }, } = useStores();
    const onNextClick = () => {
        if (!address.postcode) {
            return setIsEmptyAddressError(true);
        }
        if (!address.floor) {
            return setIsEmptyFloorError(true);
        }
        setCurrentStepIndex(1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(GetAddress, { isDisabled: false, errorText: isEmptyAddressError ? 'Please enter your postcode' : '', defaultValue: address.address, onChange: addr => {
                    setAddress({ ...address, ...addr, location: addr.coords });
                    setIsEmptyAddressError(false);
                }, title: 'Delivery postcode' }), _jsx("div", { style: { marginTop: 16, maxWidth: 186 }, children: _jsx(Combobox, { items: floorItems, errorText: isEmptyFloorError ? 'Please enter your floor' : '', title: 'Floor', onChange: floor => {
                        setAddress({ ...address, floor });
                        setIsEmptyFloorError(false);
                    }, defaultValue: address.floor.toString() }) }), _jsxs("div", { style: { marginTop: 16 }, children: [_jsx(ToggleRow, { label: `parking_return`, iconUrl: ParkingIcon, isChecked: address.parking, onChange: v => {
                            setAddress({ ...address, parking: v });
                        }, title: "The apartment has a parking space close by that movers can use" }), _jsx(ToggleRow, { label: `elevator_return`, iconUrl: ElevatorIcon, isChecked: address.elevator, onChange: v => {
                            setAddress({ ...address, elevator: v });
                        }, title: "Apartments with lift" })] }), _jsx(BottomButtonsWrapper, { children: _jsx(Button, { onClick: onNextClick, full: true, 
                    // $mobileSmall
                    kind: 'primary', loading: false, type: "button", children: "Continue" }) })] }));
};
export default observer(Address);
