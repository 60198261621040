import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BlockContent, StyledIconBtnBLock } from '#Components/CommonStyled';
import { DesignFonts } from '../../style/DesignFonts';
import styled from 'styled-components';
const Title = styled.span `
  ${DesignFonts.bodyRegular}
`;
const IconBtnBLock = ({ iconUrl, title, onClick, full, children, disabled, lined }) => {
    return (_jsx(StyledIconBtnBLock, { "$lined": lined, "$full": full, "$thin": true, onClick: disabled ? undefined : onClick, children: _jsxs(BlockContent, { "$lined": lined, children: [_jsx("img", { style: { filter: disabled ? 'opacity(0.5)' : '' }, src: iconUrl, alt: "icon" }), _jsx(Title, { style: { filter: disabled ? 'opacity(0.5)' : '' }, children: title }), children] }) }));
};
export default IconBtnBLock;
