import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Header } from '#Components';
import SpaceInfoLink from '#Components/shared/SpaceInfoLink';
import { useStores } from '#Hooks';
import { ApiService } from '#Services';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import ArchivedSpace from './ArchivedSpace';
import { observer } from 'mobx-react-lite';
const api = new ApiService();
const Archive = () => {
    const { clientId } = useParams();
    const { spaceData } = useStores();
    const { data } = useQuery({
        queryKey: ['client', clientId],
        queryFn: ({ queryKey }) => {
            const [_, clientId] = queryKey;
            return api.getClient(Number(clientId));
        },
    });
    const onaBackCLick = () => {
        spaceData.setSpace(null);
        spaceData.clean();
    };
    const onSpaceLinkClick = (space) => {
        spaceData.setSpaceData(space);
        spaceData.setSpace(space);
    };
    const archivedSpaces = data?.spaces.filter(space => space.active);
    return (_jsx(_Fragment, { children: !spaceData.space ? (_jsxs(_Fragment, { children: [_jsx(Header, { title: _jsx("span", { style: { color: 'var(--main-color)' }, children: "Archived Spaces" }), subtitle: `Client ID ${clientId}` }), !archivedSpaces?.length ? (_jsx("h5", { style: { textAlign: 'center' }, children: "No archived spaces yet" })) : (archivedSpaces.map(space => _jsx(SpaceInfoLink, { onClick: () => onSpaceLinkClick(space), space: space })))] })) : (_jsx(ArchivedSpace, { onClose: onaBackCLick, space: spaceData.space })) }));
};
export default observer(Archive);
