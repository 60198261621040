import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import SwipeContainer from '../SwipeContainer';
import { FormInput } from '#Components/shared';
import { BlockTitle, SearchListItem } from '#Components/CommonStyled';
import styled from 'styled-components';
import { DesignFonts } from '../../../style/DesignFonts';
import Loader from '#Components/shared/Loader';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useErrorToastEffect } from '#Hooks';
import { ApiService } from '#Services';
const api = new ApiService();
const HeaderButtons = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const StoragesDelimiter = styled.div `
  width: 100%;
  ${DesignFonts.bodyDemiBold}
  display: flex;
  align-items: center;
  color: var(--main-dark-gray);
  margin: 16px 0;

  ::before,
  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--main-gray);
    margin: 0 10px;
  }
`;
const HeaderButton = styled.button `
  background: none;
  border: none;
  cursor: pointer;

  ${DesignFonts.bodyDemiBold}
  :disabled {
    color: var(--main-gray);
  }

  ${props => props.$active &&
    `
        color: var(--main-color);
    `}
`;
const TransferSpace = ({ onClose, open, onTransfer, transferring }) => {
    const [filter, setFilter] = useState('');
    const [selectedUnitId, setSelectedUnitId] = useState(null);
    const { storageId } = useParams();
    const { data, error, isLoading } = useQuery({
        queryKey: ['storage', storageId],
        queryFn: () => api.getAllStorages(),
    });
    useErrorToastEffect(error, 'Error with storage loading');
    // const filteredStorageUnits = data?.filter(storage => {
    //   return (
    //     storage.units.find(unit => {
    //       return unit.title.toString().toLowerCase().includes(filter.toLowerCase());
    //     }) !== undefined
    //   );
    // });
    const filteredStorageUnits = data?.reduce((acc, storage) => {
        const filteredUnits = storage.units.filter(unit => unit.title.toString().toLowerCase().includes(filter.toLowerCase()));
        if (filteredUnits.length > 0) {
            acc.push({
                id: storage.id,
                title: storage.title,
                units: filteredUnits,
            });
        }
        return acc;
    }, []);
    const onTransferClick = () => {
        onTransfer(selectedUnitId || 0);
    };
    return (_jsx(_Fragment, { children: _jsx(SwipeContainer, { opened: open, onClose: onClose, children: _jsxs("div", { id: "transfer_unit_list", style: { display: 'flex', flexDirection: 'column', minHeight: '80vh' }, children: [_jsxs(HeaderButtons, { children: [_jsx(HeaderButton, { onClick: onClose, children: "Cancel" }), _jsx(HeaderButton, { disabled: !selectedUnitId || transferring, "$active": !!selectedUnitId && !transferring, onClick: onTransferClick, children: "Transfer" })] }), _jsx(BlockTitle, { children: "Please pick a unit number" }), _jsx(FormInput, { onClear: () => setFilter(''), bottomMargin: 10, name: "Search unit", onChange: setFilter, value: filter }), (transferring || isLoading) && _jsx(Loader, {}), _jsx("div", { style: { marginBottom: 20, maxHeight: 300, overflow: 'auto', zIndex: 1001 }, children: filteredStorageUnits?.map(storage => (_jsxs(_Fragment, { children: [_jsx(StoragesDelimiter, { children: storage.title }), storage.units.map(unit => (_jsxs(SearchListItem, { onClick: () => setSelectedUnitId(Number(unit.id)), "$selected": unit.id === selectedUnitId, children: [_jsx("span", { children: unit.title }), _jsxs("p", { children: [unit.spaces_ids.length, " spaces"] })] }, unit.id)))] }))) })] }) }) }));
};
export default TransferSpace;
