import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { HeaderButton, HeaderButtons } from '#Components/CommonStyled';
import Loader from '#Components/shared/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '#Services';
import { useToast } from '../../providers/ToastProvider';
import SwipeContainer from '#Components/AddSpace/SwipeContainer';
import DateModule from '#Components/StorageReturn/shared/Date/DateModule';
const api = new ApiService();
const RescheduleModal = ({ onClose, open, onRemove }) => {
    const { addToast } = useToast();
    const [updating, setUpdating] = useState(false);
    const { returnId, clientId } = useParams();
    const navigate = useNavigate();
    const onChange = async () => {
        try {
            setUpdating(true);
            await api.postCancelHubSpotOrder(Number(returnId));
            addToast &&
                addToast({
                    message: 'Order successfully removed',
                    type: 'success',
                });
            navigate(`/clients/${clientId}`);
        }
        catch (error) {
            addToast &&
                addToast({
                    message: 'Something went wrong',
                    type: 'error',
                });
        }
        finally {
            setUpdating(false);
        }
    };
    return (_jsx(_Fragment, { children: _jsx(SwipeContainer, { opened: open, onClose: onClose, children: _jsxs("div", { id: "reschedule_order", style: { display: 'flex', flexDirection: 'column', minHeight: '80vh' }, children: [_jsxs(HeaderButtons, { children: [_jsx(HeaderButton, { onClick: onClose, children: "Back" }), _jsx(HeaderButton, { disabled: false, 
                                // $active={!!selectedUnitId && !transferring}
                                onClick: onChange, children: "Change" })] }), _jsx(DateModule, {}), updating && _jsx(Loader, {})] }) }) }));
};
export default RescheduleModal;
